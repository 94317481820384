import { ChangeEvent, FC, FormEvent, MouseEvent, useState } from 'react'
import Button from 'components/Button'

import './ContactForm.scss'

const ContactForm: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [formData, setFormData] = useState<{
    first?: string
    last?: string
    email: string
    phone: string
    subject?: string
    message?: string
  }>({
    email: '',
    phone: '',
  })

  const showForm = () => {
    setIsVisible(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hideForm = () => {
    setIsVisible(false)
  }

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).className === 'overlay') {
      hideForm()
    }
  }

  const sendMessage = async (formData: {
    first?: string
    last?: string
    email: string
    phone: string
    subject?: string
    message?: string
  }) => {
    hideForm()

    const formspreeUrl = process.env.REACT_APP_FORMSPREE_URL || ''

    try {
      const response = await fetch(formspreeUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      if (response.ok) {
        alert('Message sent successfully')
      } else {
        alert('Failed to send message')
      }
    } catch (error) {
      console.error('Failed to send message:', error)
      alert('Failed to send message')
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendMessage(formData)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      {isVisible && (
        <div className='overlay' onClick={handleOverlayClick}>
          <div className='contact-form'>
            <button className='close-button' onClick={hideForm}>
              X
            </button>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className='contact-form-field'>
                <label>First name:</label>
                <input
                  type='text'
                  name='first'
                  value={formData.first}
                  placeholder='Jane'
                  onChange={handleChange}
                />
              </div>
              <div className='contact-form-field'>
                <label>Last name:</label>
                <input
                  type='text'
                  name='last'
                  value={formData.last}
                  placeholder='Doe'
                  onChange={handleChange}
                />
              </div>
              <div className='contact-form-field'>
                <label>
                  Email:
                  <span className='contact-form-field-required' aria-hidden='true'>
                    *
                  </span>
                </label>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  placeholder='jane.doe@example.com'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='contact-form-field'>
                <label>
                  Phone:
                  <span className='contact-form-field-required' aria-hidden='true'>
                    *
                  </span>
                </label>
                <input
                  type='tel'
                  name='phone'
                  value={formData.phone}
                  placeholder='Phone number'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='contact-form-field'>
                <label>
                  Subject:
                  <span className='contact-form-field-required' aria-hidden='true'>
                    *
                  </span>
                </label>
                <input
                  type='text'
                  name='subject'
                  value={formData.subject}
                  placeholder='Subject'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='contact-form-field'>
                <label>
                  Message:
                  <span className='contact-form-field-required' aria-hidden='true'>
                    *
                  </span>
                </label>
                <textarea
                  name='message'
                  value={formData.message}
                  placeholder='Please type your message'
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <p className='contact-form-response'>
                We’ll do our best to get back to you within 6-8 working hours.
              </p>
              <div className='contact-form-submit'>
                <p>
                  <strong>Working hours:</strong> 9h-17h UTC+1
                  <br />
                  <strong>Email:</strong>{' '}
                  <a href='mailto:%20info@futsalstats.com'>info@futsalstats.com</a>
                  <br />
                  <strong>Phone number:</strong> <a href='tel: +381691046701'>(381) 69 1046701</a>
                </p>
                <button type='submit'>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      )}
      <Button className='contact-button' text='Contact us' onClick={showForm} />
    </>
  )
}

export default ContactForm
