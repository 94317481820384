import { FC, useRef, useState } from 'react'

import Button from 'components/Button'
import Logo from 'components/Logo'
import { useWindowResize } from 'hooks/useWindowSize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import './WebsiteNavbar.scss'
import { ROUTES } from 'constants/routes'

const WebsiteNavbar: FC = () => {
  const [icon, setIcon] = useState(faBars)
  const [width] = useWindowResize()
  const mobileNav = useRef<HTMLDivElement>(null)
  const hamburgerRef = useRef<HTMLButtonElement>(null)
  const loginRef = useRef<HTMLButtonElement>(null)

  const handleClick = (targetSectionId: string) => {
    handleBarsClick()
    const targetSection = document.getElementById(targetSectionId)
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleBarsClick = () => {
    if (mobileNav.current) {
      if (mobileNav.current.style.display == 'flex') {
        mobileNav.current.style.display = 'none'
        setIcon(faBars)

        if (loginRef.current) {
          loginRef.current.style.display = 'block'
        }
      } else {
        mobileNav.current.style.display = 'flex'
        setIcon(faTimes)
        if (loginRef.current) {
          loginRef.current.style.display = 'none'
        }
      }
    }
  }

  return (
    <>
      <nav className='website'>
        <div className='website-container'>
          <Logo className='website-container-logo' link={ROUTES.WEBSITE.PATH} />
          <div className='website-container-actions'>
            <div className='website-container-actions-local'>
              <Button
                text='Pricing'
                onClick={() => handleClick('pricing')}
                className='website-container-actions-local-pricing'
                style={{
                  height: '48px',
                  width: 'fit-content',
                  borderRadius: '74px',
                  fontFamily: 'var(--font-bold)',
                  fontSize:
                    width > 450
                      ? 'var(--font-size-m)'
                      : width > 300
                      ? 'var(--font-size-s)'
                      : 'var(--font-size-xs)',
                  lineHeight: '20px',
                  textAlign: 'center',
                  border: 'none',
                }}
              />
              <Button
                id='navbar-hamburger'
                text='Download app'
                onClick={() => handleClick('download-app')}
                className='website-container-actions-local-download'
                style={{
                  height: '48px',
                  width: 'fit-content',
                  borderRadius: '74px',
                  fontFamily: 'var(--font-bold)',
                  fontSize:
                    width > 450
                      ? 'var(--font-size-m)'
                      : width > 300
                      ? 'var(--font-size-s)'
                      : 'var(--font-size-xs)',
                  lineHeight: '20px',
                  textAlign: 'center',
                  border: 'none',
                }}
              />
            </div>
          </div>
          <div className='website-container-mobile'>
            <Button
              ref={hamburgerRef}
              text=''
              onClick={() => handleBarsClick()}
              className='website-container-mobile-bars'
              style={{}}
            >
              <FontAwesomeIcon icon={icon} />
            </Button>
          </div>
        </div>
      </nav>
      <div className='website-local' ref={mobileNav}>
        <Button
          text='Pricing'
          onClick={() => handleClick('pricing')}
          className='website-local-pricing'
          style={{
            height: '48px',
            width: 'fit-content',
            borderRadius: '74px',
            fontFamily: 'var(--font-bold)',
            fontSize:
              width > 450
                ? 'var(--font-size-m)'
                : width > 300
                ? 'var(--font-size-s)'
                : 'var(--font-size-xs)',
            lineHeight: '20px',
            textAlign: 'center',
            border: 'none',
          }}
        />
        <Button
          text='Download app'
          onClick={() => handleClick('download-app')}
          className='website-local-download'
          style={{
            height: '48px',
            width: 'fit-content',
            borderRadius: '74px',
            fontFamily: 'var(--font-bold)',
            fontSize:
              width > 450
                ? 'var(--font-size-m)'
                : width > 300
                ? 'var(--font-size-s)'
                : 'var(--font-size-xs)',
            lineHeight: '20px',
            textAlign: 'center',
            border: 'none',
          }}
        />
      </div>
    </>
  )
}

export default WebsiteNavbar
